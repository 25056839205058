import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

const FingoDashBoardBox = ({ title, children, ...props }) => (
  <Box
    height={220}
    width="100%"
    borderRadius="8px"
    boxShadow={3}
    p={1}
    py={2}
    {...props}
  >
    <Typography textAlign="center">
      {title}
    </Typography>
    {children}
  </Box>
);

FingoDashBoardBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FingoDashBoardBox;
