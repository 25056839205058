import React from 'react';
import { EXPORT_INVOICES, MY_SALES_PURCHASES } from '@fingo/lib/graphql';
import { DocumentList } from '@fingo/lib/components/lists';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import { useGetCountryFromUrl } from '@fingo/lib/hooks';
import { useReactiveVar } from '@apollo/client';
import currencyVar from '@fingo/lib/apollo/reactive-variables/money';
import purchasesDte from '../../../../features/collection/constants/my-purchases';

const MyPurchases = () => {
  const country = useGetCountryFromUrl();
  const dteTypes = country ? purchasesDte[country.name] : [];
  const currency = useReactiveVar(currencyVar);

  return (
    <DocumentList
      trackerId="MY_PURCHASES"
      type="my-purchases"
      headerTitle="Cuentas por pagar"
      queryDocument={MY_SALES_PURCHASES}
      showFilters
      customVariables={{
        dteType_Code_In: dteTypes,
        currency,
      }}
      includeHeaders={[
        'folio',
        'dteType_Code',
        'dateIssued',
        'company_MasterEntity_Name',
        'amountWithIva',
        'siiStatus',
        'chat',
      ]}
      mobileHeaders={[
        'folio',
        'company_MasterEntity_Name',
        'amountWithIva',
        'siiStatus',
      ]}
      showExportInvoice
      queryExport={EXPORT_INVOICES}
      preColumns={useInvoicePreColumns()}
    />
  );
};

export default MyPurchases;
