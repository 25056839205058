import React from 'react';
import dayjs from '@fingo/lib/config/dayjs';
import { ConfirmingPortfolio } from '@fingo/lib/views';
import ConfirmingUploadActions from '@fingo/lib/views/confirming/columns/views/ConfirmingUploadActions';
import { formatGraphQlDatetime } from '@fingo/lib/helpers';

const ConfirmingUpload = () => (
  <ConfirmingPortfolio
    Actions={ConfirmingUploadActions}
    queryCustomVariables={{
      status_Status: 'PENDING',
      createdAt_Gte: formatGraphQlDatetime(dayjs().subtract(2, 'weeks')),
    }}
  />
);

export default ConfirmingUpload;
